import React from 'react';
import ReactDOM from 'react-dom';
import { HashRouter, Route, Routes } from 'react-router-dom';
import reportWebVitals from './reportWebVitals';

import Home from './javascript/pages/home.js';
import NotFound from './javascript/pages/notfound.js';

export default function App() {
	return (
		<React.StrictMode>
			<HashRouter basename='/'>
				<Routes>
					<Route path='/' element={<Home />} />
					<Route path='/*' element={<NotFound />} />
				</Routes>
			</HashRouter>
		</React.StrictMode>
	);
}

ReactDOM.render(<App />, document.getElementById('root'));

reportWebVitals(console.log);