import React from 'react';

export default function App() {
	return (
		<div>
			<p id='welcomeText'>Welcome, my name is</p>
			<h1>Morgan Rose Godden</h1>

			<hr className='rotate180' />
		</div>
	);
}
